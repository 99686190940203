<template>

  <div class="login-container">
    <b-row>
      <b-col md="12">
        <div class="basic-1 title-1-bold-24 d-flex"
             style="justify-content: center; align-items: center; margin-bottom: 40px">
          {{ $t("AUTH.CREATE_PASSWORD") }}
        </div>
      </b-col>
      <b-col md="6" class="mb-5 first-col">
        <div class="auth-subtitle mb-6">
          {{ $t("AUTH.CREATE_PASSWORD_INFO") }}</div>
      </b-col>
      <b-col md="6" class="mb-5">
        <form class="form sh-auth-form create-password"
              style="position: relative"
              novalidate="novalidate" id="sh_login_form">
          <div
              v-popover:password
              v-popover:info.left
              v-popover.left="{ name: 'password' }"
              class="form-floating mb-4">

            <input type="password"
                   :class="'form-control ' + (this.valid ? '' : 'input-invalid')"
                   v-model="model[fields.password.name]"
                   :id="fields.password.name"
                   v-on:change="handleChangePassword"
                   :placeholder="fields.password.label">
            <label for="floatingPassword">{{ fields.password.label }}</label>

          </div>

          <popover name="password" position="left" :key="this.model.password">
            <div class="rs-tooltip-inner">
              <h6 style="font-size: 0.9rem;">
                {{ $t('GENERAL.PASSWORD_MUST_CONTAIN') }}</h6>
              <div class="criteria" style="display: flex; padding-top: 5px;">
                <unicon :name="passwordLength ? 'check' : 'multiply'"
                        width="16" height="16" :fill="passwordLength ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                <p>{{ $t('GENERAL.AR_LEAST_6_CHARACTERS') }}</p></div>
              <div class="criteria" style="display: flex; padding-top: 5px;">
                <unicon :name="containsLetter ? 'check' : 'multiply'"
                        width="16" height="16" :fill="containsLetter ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                <p>{{ $t('GENERAL.AR_LEAST_ONE_LETTER') }}</p></div>
              <div class="criteria" style="display: flex; padding-top: 5px;">
                <unicon :name="containsNumbers ? 'check' : 'multiply'"
                        width="16" height="16" :fill="containsNumbers ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                <p>{{ $t('GENERAL.AR_LEAST_ONE_NUMBER') }}</p></div>
              <div class="criteria" style="display: flex; padding-top: 5px;">
                <unicon :name="containsSymbols ? 'check' : 'multiply'"
                        width="16" height="16" :fill="containsSymbols ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                <p>{{ $t('GENERAL.AR_LEAST_ONE_TOKEN') }}</p></div>
            </div>
          </popover>
          <div class="form-floating mb-4">
          <input type="password"
                 :class="'form-control ' + (this.validConfirm ? '' : 'input-invalid')"
                 v-model="model[fields.password_confirm.name]"
                 :id="fields.password_confirm.name"
                 v-on:change="handleChangePasswordConfirm"
                 :placeholder="fields.password_confirm.label">
          <label for="floatingPassword">{{ fields.password_confirm.label }}</label>
        </div>
          <div class="pb-lg-0 mb-5">
            <base-button
              block
              variant="secondary"
              :callback="doSubmit" :callbackParams="1">{{ $t('GENERAL.SAVE') }}</base-button>
          </div>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserModel from '@/modules/auth/user-model';
import { server } from '@/const';
import Notify from '@/shared/utils/notify';
import Vue from 'vue';
import VPopover from 'vue-js-popover';
import i18n from '@/shared/plugins/vue-i18n';

const { fields } = UserModel;
Vue.use(VPopover, { tooltip: true });

export default {
  name: 'CreatePassword',
  data() {
    return {
      fields,
      model: {},
      passwordLength: false,
      containsNumbers: false,
      containsLetter: false,
      containsSymbols: false,
      valid: false,
      validConfirm: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    doSubmit() {
      this.handleChangePassword({ target: { value: this.model.password } });
      if (this.password !== 0 && (this.valid === false || this.validConfirm === false)) {
        Notify('warning', i18n.t('AUTH.ERROR_PASSWORD'));
        return;
      }
      const loginPath = '/WebResetPasswordFinish.php';

      const data = new FormData();
      data.append('code', this.$route.params.code);
      data.append('password', this.model.password);
      data.append('password_confirm', this.model.password_confirm);
      fetch(server + loginPath, {
        method: 'POST',
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            Notify('warning', json.error);
          }
          if (json.status === 'ok') {
            this.$router.push({ name: 'succesCreatePassword' });
          }
        });
    },

    handleChangePassword(e) {
      const number = e.target.value.match(/\d+/g);
      const letters = e.target.value.match(/[a-z]/);
      const symbols = new RegExp(/[^A-Z a-z0-9]/);

      this.passwordLength = e.target.value.length > 7;
      this.containsNumbers = number != null;
      this.containsLetter = letters != null;
      this.containsSymbols = symbols.test(e.target.value);
      this.valid = e.target.value.length > 7 && number != null
          && letters != null && symbols.test(e.target.value);
      this.validConfirm = e.target.value === this.model.password_confirm;
    },
    handleChangePasswordConfirm(e) {
      this.validConfirm = e.target.value === this.model.password;
    },
  },
};
</script>
<style>
.error-password{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #D66A5B;
  margin-bottom: 4px;
}
.login-container{
  @media (min-width: 768px) {
    .col-md-6{
      padding: 0 40px;
    }
    .first-col {
      border-right: 1px solid #E0E0E0;
    }

  }
}
.create-password{
  .vue-popover{
    left: -200px!important;
    top: -76px!important;
  }
}

</style>
